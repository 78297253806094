@import '../../../../../variables.scss';

.TestSuiteTestAssetView {
    padding: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .Error {
        margin: 0 0 1rem;
        padding: 15px;
        color: #721c24;
        background-color: #f8d7da;
        border: solid 1px #f5c6cb;
    }

    .TestSuiteTestAssetCompleted {
        padding: 15px;
        border-radius: 5px;
        margin: 1.5rem 0 0;
        color: #155724;
        background-color: #d4edda;
        border: solid 1px #c3e6cb;
    }

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    &Detail {
        background-color: $grey;
        padding: 15px;
        margin: 1.5rem 0;

        h2 {
            font-size: 1rem;
            color: $dark-grey;
            font-weight: 600;
            margin: 0 0 1rem;
        }
        h1 {
            font-size: 1rem;
            font-weight: 600;
            margin: 0 0 1rem;
            color: $primary;
        }
        h3 {
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
        }
        p {
            span {
                & + span {
                    &:before {
                        content: '|';
                        margin: 0 7px 0 4px;
                        color: $dark-grey;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .TestSuiteTestAssetConfiguration {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1rem;
        &.pending {
            background-color: $grey;
        }
        &.started {
            background-color: lighten($secondary, 50%);
        }
        &.complete {
            background-color: lighten(#77DD77, 25%);
        }
        > div:not(.content) {
            margin-right: auto;
            strong {
                display: block;
            }
            p {
                margin: 0;
            }
        }
        & + .TestSuiteTestAssetConfiguration {
            margin-top: 1rem;
        }

        > span {
            background-color: $secondary;
            color: #FFFFFF;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 10px;
                height: 10px;
                transform: rotate(90deg);
            }
        }
        &.open {
            & > span {
                svg {
                    transform: rotate(-90deg);
                }
            }
        }
        .content {
            width: 100%;
            transition: all 150ms;
            &-inner {
                margin-top: 1rem;
            }

            .input-unit {
                display: flex;
                input {
                    flex: 1;
                    padding: 0.5rem;
                    border: solid 1px $dark-grey;
                    outline: none;
                }
                span {
                    padding: 0.5rem;
                    background-color: $secondary;
                    color: #FFFFFF;
                    font-size: 14px;
                }
            }

            .input-file {
                padding: 0.5rem;
                border: solid 1px $dark-grey;
                outline: none;
                background-color: #FFFFFF;
            }

            .input-checkbox {
                position: relative;
                display: flex;
                input {
                    flex: 0;
                    position: absolute;
                    opacity: 0;
                    left: -100px;
                    &:checked {
                        & + label {
                            background-color: $secondary;
                            color: #FFFFFF;
                        }
                    }
                }
                label {
                    border: solid 1px $dark-grey;
                    display: inline-block;
                    padding: 0.5rem;
                    background-color: #FFFFFF;
                    font-size: 14px;
                    text-align: center;
                    flex: 1;
                    & + input + label {
                        margin-left: -1px;
                    }
                }
            }
        }
        &:not(.open) {
            & > .content {
                max-height: 0;
                overflow: hidden;
            }
        }
        .ConfigurationActions {
        }
    }

    .ButtonGroup {
        margin-top: 1rem;
        display: flex;
        button {
            flex: 1;
            border: solid 1px $dark-grey;
            background-color: #FFFFFF;
            padding: 0.5rem;
            &.pass {
                background-color: rgba(#77DD77, 0.25);
                &.active {
                    background-color: rgba(#77DD77, 0.75);
                }
            }
            &.fail {
                background-color: rgba(red, 0.15);
                &.active {
                    background-color: rgba(red, 0.75);
                }
            }
            &.skip {
                background-color: rgba($dark-grey, 0.25);
                &.active {
                    background-color: rgba($dark-grey, 0.75);
                }
            }
            & + button {
                margin-left: -1px;
            }
        }
    }
    
    .ConfigurationReason {
        margin-top: 1rem;
        p {
            font-size: 14px;
            margin: 0 0 0.5rem;
        }
        textarea {
            width: 100%;
            border: solid 1px $dark-grey;
            font-size: 14px;
            padding: 0.5rem;
            outline: 0;
        }
    }

    .Back {
        font-size: 14px;
        text-decoration: none;
        display: block;
        color: $secondary;
        span {
            background-color: $secondary;
            color: #FFFFFF;
            width: 20px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: 0;
            margin: 0 10px 0 0;
            svg {
                width: 10px;
                height: 10px;
                transform: scale(-1);
            }
        }
    }
}