@import '../variables.scss';

.ForgottenPassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &Form {
        width: 100%;
        > input {
            display: block;
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 1.5rem 0 0;
            width: 100%;
            font-size: 1rem;
            background-color: #FFFFFF;
            &:disabled {
                background-color: #FFFFFF;
            }
        }
        > p {
            text-align: center;
            font-size: 1rem;
            margin: 1.5rem 0 0;
            a {
                color: $secondary;
            }
        }
        > button {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 1.5rem 0 0;
            width: 100%;
            display: block;
            background-color: $secondary;
            color: #FFFFFF;
            font-size: 1rem;
            &[type="button"] {
                background-color: transparent;
                color: $secondary;
                margin: 0.5rem 0 0;
            }
        }
    }
}