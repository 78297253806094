@import '../../variables.scss';

.PrivateLayout {
    flex: 1;
    display: flex;
    flex-direction: column;
    > header {
        background-color: $primary;
        padding: 15px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            max-width: 100px;
            height: auto;
        }

        .Notifications {
            background-color: transparent;
            padding: 0;
            margin: 0 0 0 auto;
            border: none;
            color: #FFFFFF;
        }
    }
    > main {
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .Offline {
            padding: 15px 25px;
            color: #721c24;
            background-color: #f8d7da;
            border: solid 1px #f5c6cb;
            border-radius: 5px;
        }
    }
    footer {
        background-color: $primary;
        display: flex;
        padding: 0 25px 0;
        button {
            border: none;
            background-color: transparent;
            color: #FFFFFF;
            padding: 20px 15px 15px;
            opacity: 0.5;
            flex: 1;
            &.active {
                opacity: 1;
            }
            & + button {
                border-left: solid 1px rgba(#FFFFFF, 0.25);
            }
        }
    }
    .Actions {
        text-align: center;
    }
    .Sync {
        padding: 0;
        margin: 5px auto 0;
        border: none;
        background-color: transparent;
        color: $secondary;
        font-weight: bold;
    }
    .Toggle {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        span {
            display: block;
            position: relative;
            &:before {
                content: '';
                border-radius: 15px;
                background-color: #FFFFFF;
                height: 25px;
                width: 52px;
                display: block;
            }
            &:after {
                content: '';
                width: 17px;
                height: 17px;
                display: block;
                position: absolute;
                top: 4px;
                left: 4px;
                background-color: $secondary;
                border-radius: 50%;
            }
        }
        input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            &:checked {
                & + span {
                    &:after {
                        left: auto;
                        right: 4px;
                    }
                }
            }
        }
    }
    .ToggleLabel {
        font-size: 10px;
        color: #FFFFFF;
        font-family: 300;
        margin: 3px auto 0;
    }
}