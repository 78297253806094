@import '../../variables.scss';

.TestSuiteView {
    padding: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .TestSuiteCompleted {
        padding: 15px;
        border-radius: 5px;
        margin: 1.5rem 0 0;
        color: #155724;
        background-color: #d4edda;
        border: solid 1px #c3e6cb;
    }

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .Back {
        font-size: 14px;
        text-decoration: none;
        display: block;
        color: $secondary;
        span {
            background-color: $secondary;
            color: #FFFFFF;
            width: 20px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: 0;
            margin: 0 10px 0 0;
            svg {
                width: 10px;
                height: 10px;
                transform: scale(-1);
            }
        }
    }
    
    .LocationError {
        margin: 1.5rem 0 0;
        padding: 15px;
        color: #721c24;
        background-color: #f8d7da;
        border: solid 1px #f5c6cb;
        border-radius: 5px;
    }

    &Detail {
        background-color: $grey;
        padding: 15px;
        margin: 1.5rem 0;

        h2 {
            font-size: 1rem;
            color: $dark-grey;
            font-weight: 600;
            margin: 0 0 1rem;
        }
        h1 {
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
            color: $primary;
        }
        p {
            span {
                & + span {
                    &:before {
                        content: '|';
                        margin: 0 7px 0 4px;
                        color: $dark-grey;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .TestSuiteLocation {
            margin: 0;
            & + .TestSuiteLocation {
                margin-top: -1rem;
            }
        }

        .TestSuiteAction {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 1rem 0 0;
            width: 100%;
            display: block;
            background-color: $secondary;
            color: $primary;
            font-size: 1rem;
            &:disabled {
                opacity: 0.5;
            }
            & + p {
                margin-top: 1.5rem;
            }
        }
        .TestSuiteLink {
            border-radius: 5px;
            border: none;
            padding: 1rem;
            margin: 0 0 1rem;
            width: 100%;
            display: block;
            background-color: transparent;
            color: $primary;
            font-size: 1rem;
            &:disabled {
                opacity: 0.5;
            }
        }
    }

    .TestSuiteTest {
        display: flex;
        align-items: center;
        padding: 1rem;
        margin-bottom: 1rem;
        &.pending {
            background-color: $grey;
        }
        &.started {
            background-color: lighten($secondary, 50%);
        }
        &.complete {
            background-color: lighten(#77DD77, 25%);
        }
        div {
            margin-right: auto;
            strong {
                display: block;
            }
            p {
                margin: 0;
                span {
                    color: #666;
                    font-size: 14px;
                }
            }
        }

        > span {
            background-color: $secondary;
            color: #FFFFFF;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 10px;
                height: 10px;
            }
        }
    }

    select {
        width: 100%;
        border: solid 1px $dark-grey;
        font-size: 14px;
        padding: 0.5rem;
        outline: 0;
        margin: 0 0 0.5rem;     
    }

    textarea {
        width: 100%;
        border: solid 1px $dark-grey;
        font-size: 14px;
        padding: 0.5rem;
        outline: 0;
        margin: 0 0 0.5rem; 
    }

    button {
        flex: 1;
        border: solid 1px $dark-grey;
        background-color: lighten($dark-grey, 35);
        padding: 0.5rem;
        &:disabled {
            opacity: 0.5;
        }
    }
}