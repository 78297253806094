@import '../../variables.scss';

.TestSuites {
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &.is--busy {
        justify-content: center;
        flex-direction: column;
    }
    h1 {
        font-size: 26px;
        text-transform: uppercase;
        margin: 0 0 1rem;
    }

    &List {
        flex: 1;
        position: relative;

        &Busy {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(#FFFFFF, 0.8);
            & + h2 {
                margin-top: 0 !important;
            }
        }

        h2 {
            font-size: 22px;
            margin: 1rem 0;
            color: $dark-grey;
            font-weight: 600;
            &:first-child {
                margin-top: 0;
            }
        }

        .TestSuitesPagination {
            display: flex;
            align-items: center;
            .Previous, .Next {
                border: none;
                padding: 1rem;
                margin: 0;
                display: block;
                background-color: $secondary;
                color: #FFFFFF;
                font-size: 1rem;
                svg {
                    width: 18px;
                    height: 18px;
                    vertical-align: -4px;
                }
            }
            .Previous {
                svg {
                    margin: 0 1rem 0 0;
                    transform: scale(-1);
                }
            }
            .Next {
                margin: 0 0 0 auto;
                svg {
                    margin: 0 0 0 1rem;
                }
            }
        }

        .refresh-view {
            height: 100%;
        }

        &Item {
            background-color: lighten($secondary, 50%);
            border: none;
            display: flex;
            width: 100%;
            align-items: center;
            padding: 1rem;
            margin: 0;
            & + * {
                margin-top: 1rem;
            }
            &Content {
                margin: 0 auto 0 0;
                flex: 1;
                text-align: left;
                strong {
                    display: block;
                    color: $secondary;
                    font-size: 16px;
                    margin: 0 0 0.4rem;
                }
                span {
                    & + span {
                        &:before {
                            content: '|';
                            margin: 0 7px 0 4px;
                            color: $dark-grey;
                        }
                    }
                }
            }
            &Status {
                margin: 0 0 0.4rem;

                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: 1px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin: 0 4px 0 0;
                }
                &.pending {
                    &:before {
                        background-color: $dark-grey;
                    }
                }
                &.started {
                    &:before {
                        background-color: $secondary;
                    }
                }
                &.complete {
                    &:before {
                        background-color: green;
                    }
                }
                span {
                    text-transform: capitalize;
                    font-size: 12px;
                }
            }
            > span {
                background-color: $secondary;
                color: #FFFFFF;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}