@import '../variables.scss';

.Debug {
    padding: 15px;

    button {
        border-radius: 5px;
        border: none;
        padding: 1rem;
        margin: 1.5rem 0 0;
        width: 100%;
        display: block;
        background-color: $secondary;
        color: #FFFFFF;
        font-size: 1rem;
        & + button {
            margin-top: 1rem;
            background-color: $primary;
        }
    }
}